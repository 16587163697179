:root {
  --background-color: #ffffff;
  --background-gradient: linear-gradient(340deg, #9fc5e8 0%, #fff 22%);
  --block-spacing: 1rem;
  --body-line-height: 1.5;
  --border-color: #f7dec5cc;
  --button-hover-background: #9fc5e8;
  --button-hover-text: #272727;
  --button-background-color: #0b5394;
  --button-text-color: #ffffff;
  --details-background-gradient: linear-gradient(
    0,
    var(--hover-background) 0%,
    #fff 22%
  );
  --error-text-color: #b71c1c;
  --font-primary: "Fira Sans", sans-serif;
  --font-secondary: "Spectral", serif;
  --heading-line-height: 1.65;
  --hover-background: rgba(188, 207, 181, 0.33);
  --hover-link-color: var(--text-color);
  --letter-spacing: calc(0.03 * var(--text-base-size));
  --link-color: #0b5394;
  --margin-bottom: calc(var(--text-scale-ratio) * var(--text-base-size));
  --text-base-size: 1em;
  --text-color: #111111;
  --text-scale-ratio: 1.2;
  --text-shadow: 1px 1px 2px #f7dec5;
  --visited-link-color: #272727;
  --word-spacing: calc(0.08 * var(--text-base-size));
}

body {
  background: var(--background-gradient);
  color: var(--text-color);
  font-family: var(--font-primary);
  font-size: var(--text-base-size);
  letter-spacing: var(--letter-spacing);
  line-height: var(--body-line-height);
  margin: 0 auto;
  min-height: 100vh;
  padding: var(--block-spacing);
  transition: all 0.6s ease-in;
  word-spacing: var(--word-spacing);
}

a {
  color: var(--link-color);
}
a:hover {
  color: var(--hover-link-color);
  text-shadow: var(--text-shadow);
}
a:visited {
  color: var(--visited-link-color);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards > li {
  flex: 0 1 calc(33% - var(--block-spacing));
  margin: 0 var(--block-spacing) 0 0;
}

.enum::after {
  content: ", ";
}
.enum:last-of-type::after {
  content: "";
}

.error {
  color: var(--error-text-color);
  font-weight: bold;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}
.result {
  margin-top: var(--block-spacing);
}
/* ICONS */
nav a,
nav svg,
button svg,
.icon svg {
  display: inline-block;
  font-size: calc(var(--text-base-size) * var(--text-scale-ratio));
  font-weight: bold;
  line-height: var(--heading-line-height);
  vertical-align: middle;
}

button svg,
nav svg {
  margin-right: calc(var(--block-spacing) / 4);
}
.icon {
  float: right;
}
.icon img {
  display: inline-block;
  width: calc(var(--text-base-size) * var(--text-scale-ratio) * 2.25);
}

/* NAVIGATION */
nav {
  background: transparent;
  backdrop-filter: blur(15px);
}

nav a {
  margin: 1ch;
  margin-left: 0;
  padding: calc(var(--block-spacing) / 2);
}

/* DETAILS accordion and results section */
details,
.result {
  background: var(--details-background-gradient);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  border-bottom: 0;
  margin-bottom: var(--margin-bottom);
  padding: var(--block-spacing);
  transition: 0.2s background linear;
}
.result {
  width: 65%;
}
details:hover,
.result:hover {
  background-color: var(--hover-background);
}

details:nth-child(2) {
  background-color: var(--hover-background);
}

details button {
  margin-top: 0;
}

details li {
  margin-bottom: calc(var(--margin-bottom) / 2);
}

details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

details img {
  max-width: 80%;
  display: block;
  margin: 0 auto var(--block-spacing);
}

details .actions {
  display: flex;
  justify-content: right;
  margin-bottom: var(--margin-bottom);
  width: 100%;
}

.actions li {
  margin-left: var(--block-spacing);
}
details .flex-container {
  margin-top: -1.5rem;
  margin-left: var(--block-spacing);
}
/* Fade in */
details article {
  opacity: 0;
}
details[open] article {
  animation: show-article 0.8s linear forwards;
}

@keyframes show-article {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

summary {
  font-weight: bold;
  padding: 0;
}

/* FORM */
form {
  margin-top: var(--block-spacing);
}

input,
label,
select,
textarea,
button {
  display: block;
}

details label[for="name"] {
  display: inline;
  margin-top: 0;
}

button,
fieldset,
input,
select,
textarea {
  border: 1px solid var(--border-color);
  border-radius: 2px;
}

button,
input,
select,
textarea {
  padding: calc(var(--block-spacing) / 2);
}

button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  font-size: calc(var(--text-base-size) * var(--text-scale-ratio));
}

fieldset {
  width: 65vw;
}

input {
  width: 33%;
}

textarea {
  width: 80%;
}

label,
button {
  margin-top: calc(var(--text-base-size * 2));
}

td button {
  margin-top: 0;
}

legend {
  font-family: var(--font-secondary);
  font-weight: bold;
}

form *:focus {
  background-color: var(--hover-background);
}

button:hover {
  background-color: var(--button-hover-background);
  color: var(--button-hover-text);
}

.form-hint {
  display: block;
  font-style: italic;
}
/* PAGE LAYOUT */
header,
footer {
  font-family: var(--font-secondary);
  margin: 1rem auto;
  padding: var(--block-spacing);
}
header {
  border-left: 0;
  border-right: 0;
  font-size: calc(var(--text-base-size) * var(--text-scale-ratio));
  font-weight: bold;
  line-height: var(--heading-line-height);
  margin-bottom: 0;
}

footer {
  border-width: 1px 0 0 0;
  font-weight: normal;
}

main {
  padding: 1ch;
}

main table {
  max-width: 65vw;
}

input,
li,
p,
td {
  margin-bottom: var(--margin-bottom);
}

/* RESPONSIVE LAYOUTS */
/* Rules for small screens up to iPad portrait width,
   or tablet/phablet screens in portrait mode */
@media screen and (max-width: 500px),
  screen and (max-width: 768px) and (orientation: portrait) {
  body {
    font-size: calc(var(--text-base-size) * var(--text-scale-ratio));
  }
  nav a {
    display: block;
    line-height: var(--body-line-height);
    padding: 0;
  }

  input {
    width: 65%;
  }
  details {
    width: 90%;
  }
  details .actions {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .actions li {
    margin-right: var(--block-spacing);
  }

  .cards {
    display: block;
  }
  .cards li {
    display: list-item;
  }
}
/* Rules for non-touch devices */
@media (pointer: fine) {
  .grabbable {
    cursor: grab;
  }
}